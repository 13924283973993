.filter-container_transaction {
    background-color: #FFFFFF;
    border-radius: 8px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 24px;
    .search_partner {
      width: 20%;
    }
  }
  
  .list-category {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      padding-bottom: 5px;
    }
  }
  