.config-env {
    display: flex;
    background-color: #fff;
    width: 100%;
    justify-content: space-between;
    padding: 20px 30px;
    align-items: center;
    border-radius: 10px;

    & h3 {
       margin: 0!important;
       padding: 0!important;
    }
}

.config-paypal {
    background-color: #fff;
    margin-top: 20px;
    border-radius: 10px;

    .config-form {
        padding: 30px;
        .ant-btn-primary {
            background-color: #e6a400;
            border-color: #e6a400;
            color: #ffffff;
            &:hover {
              background: #cc9d24 !important;
              border-color: #cc9d24;
            }
        }
    }
}

.spin-loadconfig {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}