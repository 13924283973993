:root {
  --height-screen: 100vh;
  --height-header: 66px;
  --width-content: 1120px;
  --max-width-screen: 100vw;
  --height-footer: 122px;
  --side-bar-max-witdth: 300px;
  --side-bar-min-witdth: 90px;

  /*  FONT FAMILY */
  --font-family-primary: 'Roboto';

  /* COLOR */
  --color-FFFFFF: #FFFFFF;
  --color-F0F5F9: #F0F5F9;
  --color-1B223C: #1B223C;
  --color-F6FDFF: #F6FDFF;
  --color-31AE6B: #31AE6B
}
