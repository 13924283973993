.apps-container {
  padding: 24px;
  .button-add {
    .ant-btn {
      background-color: #e6a400;
      border-color: #e6a400;
      color: #ffffff;
      &:hover {
        background-color: #cc9d24;
        border-color: #cc9d24;
      }
    }
  }
}

.ant-popover-content {
  .ant-btn-primary {
    background-color: #e6a400;
    border-color: #e6a400;
    color: #ffffff;
    &:hover {
      background: #cc9d24 !important;
      border-color: #cc9d24;
    }
  }
}

.filter-container {
  border-radius: 8px;
  width: 100%;
  .filter-apps {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    .search_app-id {
      width: 20%;
    }
    .other-filter_apps {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .other-filter__apps-item {
        margin-right: 20px;
        display: flex;
        align-items: center;
        .select-filter {
          margin-left: 10px;
        }
      }
    }
  }
}

.button-add {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}

.apps-table {
  margin: 24px 0;
  .list-category {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      padding-bottom: 5px;
    }
  }
}
