.filter-user {
  display: flex;
  justify-content: space-between;
  flex: 1;
  .search_username {
    display: flex;
    align-items: center;
    input {
      max-width: 160px;
    }
  }

  span {
    margin-bottom: 0;
    font-size: 14px;
    margin-right: 12px;
  }

  .other-filter {
    display: flex;
    align-items: center;

    &__item {
      margin-left: 12px;
    }
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.pricing {
  text-align: center;
  width: 80%;
  .title {
    margin-top: 30px;
    color: #cc9d24;
    font-size: 24px;
    font-weight: 600;
  }
  .description {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .price-table {
    width: 100%;
    border-collapse: collapse;
    border: 0 none;
    margin: 50px 0;
  }
  .price-table tr:nth-child(even):not(:last-child):not(:nth-child(2)) {
    background-color: #fafafa;
  }
  .price-table tr:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  }
  .price-table tr td {
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    padding: 8px 24px;
    font-size: 14px;
  }
  .price-table tr td:first-child {
    border-left: 0 none;
    text-align: left;
  }
  .price-table tr td:not(:first-child) {
    text-align: center;
  }
  .price-table tr:nth-child(even) {
    background-color: #ffffff;
  }
  .price-table tr:hover {
    background-color: #fff8e6;
  }
  .price-table .fa-check {
    color: #000e2d;
  }
  .price-table .fa-times {
    color: #d8d6e3;
  }

  .price-table tr.price-table-head td {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .price-table tr.price-table-head {
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      #1b223c 0%,
      #1b223c 32%,
      #1b223c 66%,
      #1b223c 90%,
      #1b223c 100%
    );
    color: #fff;
  }
  .price-table td.price {
    color: #e6a400;
    padding: 16px 24px;
    font-size: 20px;
    font-weight: 600;
  }
  .price-table td.price a {
    margin-top: 16px;
  }
  .price-table td.price span {
    line-height: 40px;
  }

  .price-table svg {
    width: 80px;
  }
}

.price-table {
  .ant-btn {
    width: 100% !important;
    max-width: 120px !important;
    background-color: #e6a400 !important;
    border-color: #e6a400 !important;
    color: #ffffff !important;
    span {
      line-height: normal !important;
    }
    &:hover {
      background-color: #cc9d24 !important;
      color: #fff !important;
    }
    p {
      margin: 0 !important;
    }
  }
}

.button-subscription {
  display: block !important;
  margin: 0 auto !important;
}

.update-sub.ant-modal {
  width: 512px !important;
  .ant-modal-content {
    background-color: #fff !important;
  }
}

.update-subscription {
  margin-top: 20px;
}


.ant-popconfirm-buttons {
  display: flex;
}