.main-layout-container {
  flex: 1;
  .main-layout {
    display: flex;
    position: relative;
    .main-content {
      flex: 1;
      overflow: auto;
      height: 93vh;
    }
  }
}

@media (max-width: 1024px) {
  .main-layout {
    display: block !important;
  }
}
