.list-app-partner {
  padding: 24px;
  h2 {
    display: flex;
    align-items: center;
    .back-page {
      cursor: pointer;
    }
  }
  .filter-add {
    .filter-container {
      margin-bottom: 20px;
    }
  }
  .button-add {
    .ant-btn {
      background-color: #e6a400;
      border-color: #e6a400;
      &:hover {
        background-color: #cc9d24;
        border-color: #cc9d24;
      }
    }
  }
}
