@media (min-width: 1024px) {
  .menu {
    display: none;
  }
}

.navbar {
  position: absolute;
  top: 50px;
  left: 10px;
  z-index: 100;
}

.ant-drawer .ant-drawer-header{
  background-color: var(--color-1B223C);
  padding: 11px 24px;
}

.ant-menu-title-content {
  display: flex;
  align-items: center;

  span {
    margin-left: 10px;
  }
}

.ant-drawer .ant-drawer-body {
  padding: 0;
}
