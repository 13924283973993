.side-menu-container {
  height: 93vh;
  background-color: var(--color-FFFFFF);

  .ant-menu-title-content {
    display: flex;
    align-items: center;

    span {
      margin-left: 10px;
    }
  }
}