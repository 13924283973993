.header-container {
    height: var(--height-header);
    padding: 0px 33px 0px 33px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--color-1B223C);

    .right-header {
      display: flex;
      align-items: center;
    }

    h3 {
      margin-bottom: 0;
      margin-right: 10px;
      color: white;
    }

    .avatar {
      display: flex;
      align-items: center;
    }
  }
  