@import 'antd/dist/reset.css';
@import '@styles/variables.scss';
@import '@styles/common.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: var(--font-family-primary);
  background-color: var(--color-F0F5F9);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: var(--font-family-primary);
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.ant-pagination-options .ant-select-selector {
  display: none;
}
.ant-pagination-options {
  display: none !important;
}
