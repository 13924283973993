.container-kw {
  padding: 24px;
  .button-add_copy {
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
    .ant-btn-primary {
      background-color: #e6a400;
      border-color: #e6a400;
      color: #ffffff;
      &:hover {
        background: #cc9d24 !important;
        border-color: #cc9d24;
      }
    }
  }
}

.ant-modal-footer {
  .ant-btn-primary {
    background-color: #e6a400;
    border-color: #e6a400;
    color: #ffffff;
    &:hover {
      background: #cc9d24 !important;
      border-color: #cc9d24;
    }
  }
}
.filter-container {
  border-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .search_partname {
    width: 20%;
  }
}

.list-category {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    padding-bottom: 5px;
  }
}
